/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BookingEntity } from './BookingEntity';
import type { DateTime } from './DateTime';
import type { EventRuleEntity } from './EventRuleEntity';
export type EventEntity = {
    id: number;
    customTitle: string | null;
    start: DateTime;
    end: DateTime;
    status: EventEntity.status;
    isCustom: boolean;
    log: Array<Record<string, any>>;
    notice: string | null;
    notifiedOverlappingExternalEventIDs: Array<string> | null;
    rule: EventRuleEntity;
    booking?: BookingEntity;
};
export namespace EventEntity {
    export enum status {
        OPEN = 'open',
        BOOKED = 'booked',
        APPROVED = 'approved',
        CANCELLED = 'cancelled',
        REJECTED = 'rejected',
        OUTAGE = 'outage',
        RESERVED = 'reserved',
    }
}

