/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckEventRuleMoveOptionsPayload } from '../models/CheckEventRuleMoveOptionsPayload';
import type { CheckEventRuleMoveOptionsResultPayload } from '../models/CheckEventRuleMoveOptionsResultPayload';
import type { EventRule } from '../models/EventRule';
import type { EventRulePayload } from '../models/EventRulePayload';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class EventRulesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get event rule
     * Get event rule
     * @param therapistId
     * @param eventRuleId
     * @returns EventRule
     * @throws ApiError
     */
    public getEventRule(
        therapistId: number,
        eventRuleId: number,
    ): CancelablePromise<EventRule> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/therapists/{therapistId}/event-rules/{eventRuleId}',
            path: {
                'therapistId': therapistId,
                'eventRuleId': eventRuleId,
            },
        });
    }
    /**
     * Update event rule
     * Update event rule
     * @param therapistId
     * @param eventRuleId
     * @param requestBody
     * @returns EventRule
     * @throws ApiError
     */
    public updateEventRule(
        therapistId: number,
        eventRuleId: number,
        requestBody: EventRulePayload,
    ): CancelablePromise<EventRule> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/therapists/{therapistId}/event-rules/{eventRuleId}',
            path: {
                'therapistId': therapistId,
                'eventRuleId': eventRuleId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete event rule
     * Delete event rule
     * @param therapistId
     * @param eventRuleId
     * @returns void
     * @throws ApiError
     */
    public deleteEventRule(
        therapistId: number,
        eventRuleId: number,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/therapists/{therapistId}/event-rules/{eventRuleId}',
            path: {
                'therapistId': therapistId,
                'eventRuleId': eventRuleId,
            },
        });
    }
    /**
     * Get all event rules
     * Get all event rules
     * @param therapistId
     * @param start Earliest start date to filter for. Optional
     * @param end Latest end date to filter for. Optional
     * @returns EventRule
     * @throws ApiError
     */
    public getAllEventRules(
        therapistId: number,
        start?: string,
        end?: string,
    ): CancelablePromise<Array<EventRule>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/therapists/{therapistId}/event-rules',
            path: {
                'therapistId': therapistId,
            },
            query: {
                'start': start,
                'end': end,
            },
        });
    }
    /**
     * Add event rule
     * Add event rule
     * @param therapistId
     * @param requestBody
     * @returns EventRule
     * @throws ApiError
     */
    public addEventRule(
        therapistId: number,
        requestBody: EventRulePayload,
    ): CancelablePromise<EventRule> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/therapists/{therapistId}/event-rules',
            path: {
                'therapistId': therapistId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Check the options for moving an event rule
     * Check the options for moving an event rule
     * @param therapistId
     * @param requestBody
     * @returns CheckEventRuleMoveOptionsResultPayload
     * @throws ApiError
     */
    public checkEventRuleMoveOptions(
        therapistId: number,
        requestBody: CheckEventRuleMoveOptionsPayload,
    ): CancelablePromise<CheckEventRuleMoveOptionsResultPayload> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/therapists/{therapistId}/event-rules/check-move-options',
            path: {
                'therapistId': therapistId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
