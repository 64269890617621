/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ExportService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * GDPR Data Export
     * GDPR Data Export
     * @param clientId
     * @returns any
     * @throws ApiError
     */
    public clientExport(
        clientId: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/clients/{clientId}/export',
            path: {
                'clientId': clientId,
            },
        });
    }
}
