/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddressEntity } from './AddressEntity';
import type { CalendarExportEntity } from './CalendarExportEntity';
import type { ContactInfoEntity } from './ContactInfoEntity';
import type { ContractEntity } from './ContractEntity';
import type { CustomNotificationTemplateEntity } from './CustomNotificationTemplateEntity';
import type { DateTime } from './DateTime';
import type { EventCategoryEntity } from './EventCategoryEntity';
import type { FileEntity } from './FileEntity';
import type { InvitedClientEntity } from './InvitedClientEntity';
import type { OpeningEntity } from './OpeningEntity';
import type { PaymentInformationEntity } from './PaymentInformationEntity';
import type { TherapistCharacteristicEntity } from './TherapistCharacteristicEntity';
import type { TherapistClientLinkingEntity } from './TherapistClientLinkingEntity';
import type { TherapistInformationOptionEntity } from './TherapistInformationOptionEntity';
import type { TherapistLanguageEntity } from './TherapistLanguageEntity';
import type { TherapistLinkEntity } from './TherapistLinkEntity';
import type { TherapistPreconditionEntity } from './TherapistPreconditionEntity';
import type { TherapistSettingsEntity } from './TherapistSettingsEntity';
import type { TherapistUserLinkingEntity } from './TherapistUserLinkingEntity';
export type TherapistEntity = {
    id: number;
    name: string;
    description: string | null;
    slug: string;
    image: string | null;
    directoryEntryEnabled: boolean;
    publicClientRegistrationEnabled: boolean;
    welcomeTourDone: boolean;
    acceptingNewClients: TherapistEntity.acceptingNewClients;
    eventConflictsJobId: string | null;
    createdAt: DateTime;
    updatedAt: DateTime;
    address: AddressEntity;
    contract: ContractEntity;
    calendarExport: CalendarExportEntity;
    link: TherapistLinkEntity;
    settings: TherapistSettingsEntity;
    informationSelections: Array<TherapistInformationOptionEntity>;
    users: Array<TherapistUserLinkingEntity>;
    clients: Array<TherapistClientLinkingEntity>;
    invitedClients: Array<InvitedClientEntity>;
    preconditions: Array<TherapistPreconditionEntity>;
    openings: Array<OpeningEntity>;
    contactInfos: Array<ContactInfoEntity>;
    languages: Array<TherapistLanguageEntity>;
    eventCategories: Array<EventCategoryEntity>;
    files: Array<FileEntity>;
    customNotificationTemplates: Array<CustomNotificationTemplateEntity>;
    paymentInformation: Array<PaymentInformationEntity>;
    characteristics: Array<TherapistCharacteristicEntity>;
};
export namespace TherapistEntity {
    export enum acceptingNewClients {
        NO = 'no',
        YES = 'yes',
        WAIT1TO2WEEKS = 'wait1To2Weeks',
        WAIT2TO4WEEKS = 'wait2To4Weeks',
        WAIT2MONTHS = 'wait2Months',
        WAIT3MONTHS = 'wait3Months',
        WAIT6MONTHS = 'wait6Months',
        WAIT1YEAR = 'wait1year',
    }
}

