/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';
import { AwsService } from './services/AwsService';
import { CalendarsService } from './services/CalendarsService';
import { CitiesService } from './services/CitiesService';
import { ContactService } from './services/ContactService';
import { FeaturesService } from './services/FeaturesService';
import { HealthService } from './services/HealthService';
import { IcalService } from './services/IcalService';
import { LinkService } from './services/LinkService';
import { PaymentsService } from './services/PaymentsService';
import { ReportService } from './services/ReportService';
import { SitemapService } from './services/SitemapService';
import { TherapistsService } from './services/TherapistsService';
type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;
export class TebutoApi {
    public readonly aws: AwsService;
    public readonly calendars: CalendarsService;
    public readonly cities: CitiesService;
    public readonly contact: ContactService;
    public readonly features: FeaturesService;
    public readonly health: HealthService;
    public readonly ical: IcalService;
    public readonly link: LinkService;
    public readonly payments: PaymentsService;
    public readonly report: ReportService;
    public readonly sitemap: SitemapService;
    public readonly therapists: TherapistsService;
    public readonly request: BaseHttpRequest;
    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? 'https://api.tebuto.local',
            VERSION: config?.VERSION ?? '1.0.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });
        this.aws = new AwsService(this.request);
        this.calendars = new CalendarsService(this.request);
        this.cities = new CitiesService(this.request);
        this.contact = new ContactService(this.request);
        this.features = new FeaturesService(this.request);
        this.health = new HealthService(this.request);
        this.ical = new IcalService(this.request);
        this.link = new LinkService(this.request);
        this.payments = new PaymentsService(this.request);
        this.report = new ReportService(this.request);
        this.sitemap = new SitemapService(this.request);
        this.therapists = new TherapistsService(this.request);
    }
}

