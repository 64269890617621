/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ContactInformationPayload = {
    id?: number;
    type: ContactInformationPayload.type;
    value: string;
    position: number;
};
export namespace ContactInformationPayload {
    export enum type {
        EMAIL = 'email',
        PHONE = 'phone',
        WEBSITE = 'website',
        WHATSAPP = 'whatsapp',
        SOCIAL_MEDIA = 'social-media',
    }
}

