/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type EventRulePayload = {
    start: string;
    endDate: string | null;
    futureBookingsDecision?: EventRulePayload.futureBookingsDecision;
    interval: number;
    categoryId: number;
};
export namespace EventRulePayload {
    export enum futureBookingsDecision {
        MOVE = 'MOVE',
        CANCEL = 'CANCEL',
        KEEP = 'KEEP',
    }
}

