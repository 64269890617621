/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ExportService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * GDPR Data Export
     * GDPR Data Export
     * @param therapistId
     * @returns any
     * @throws ApiError
     */
    public therapistExport(
        therapistId: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/therapists/{therapistId}/export',
            path: {
                'therapistId': therapistId,
            },
        });
    }
}
