/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BookedEvent } from '../models/BookedEvent';
import type { CheckEventMoveOptionsPayload } from '../models/CheckEventMoveOptionsPayload';
import type { CheckEventMoveOptionsResultPayload } from '../models/CheckEventMoveOptionsResultPayload';
import type { EventOverview } from '../models/EventOverview';
import type { ReserveEventPayload } from '../models/ReserveEventPayload';
import type { UpdateEventPayload } from '../models/UpdateEventPayload';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class EventsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get overview of events of a therapist
     * Get Event Overview
     * @param therapistId
     * @param start Start date to filter for. Optional
     * @param end End date to filter for. Optional
     * @returns EventOverview
     * @throws ApiError
     */
    public getEventOverview(
        therapistId: number,
        start: string,
        end: string,
    ): CancelablePromise<EventOverview> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/therapists/{therapistId}/events',
            path: {
                'therapistId': therapistId,
            },
            query: {
                'start': start,
                'end': end,
            },
        });
    }
    /**
     * Update event of a therapist
     * Update Event
     * @param therapistId
     * @param eventId
     * @param requestBody
     * @returns BookedEvent
     * @throws ApiError
     */
    public updateEvent(
        therapistId: number,
        eventId: number,
        requestBody: UpdateEventPayload,
    ): CancelablePromise<BookedEvent> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/therapists/{therapistId}/events/{eventId}',
            path: {
                'therapistId': therapistId,
                'eventId': eventId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Check the options for moving an event
     * Check the options for moving an event
     * @param therapistId
     * @param eventId
     * @param requestBody
     * @returns CheckEventMoveOptionsResultPayload
     * @throws ApiError
     */
    public checkEventMoveOptions(
        therapistId: number,
        eventId: number,
        requestBody: CheckEventMoveOptionsPayload,
    ): CancelablePromise<CheckEventMoveOptionsResultPayload> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/therapists/{therapistId}/events/{eventId}/check-move-options',
            path: {
                'therapistId': therapistId,
                'eventId': eventId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Reserve event of a therapist
     * Reserve Event
     * @param therapistId
     * @param requestBody
     * @returns BookedEvent
     * @throws ApiError
     */
    public reserveEvent(
        therapistId: number,
        requestBody: ReserveEventPayload,
    ): CancelablePromise<BookedEvent> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/therapists/{therapistId}/events/reserve',
            path: {
                'therapistId': therapistId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Release reserved event of a therapist
     * Release Reserved Event
     * @param therapistId
     * @param eventId
     * @returns any
     * @throws ApiError
     */
    public releaseReservedEvent(
        therapistId: number,
        eventId: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/therapists/{therapistId}/events/{eventId}/release',
            path: {
                'therapistId': therapistId,
                'eventId': eventId,
            },
        });
    }
}
