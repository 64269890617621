/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ContactInfo = {
    id: number;
    type: ContactInfo.type;
    value: string;
    position: number;
};
export namespace ContactInfo {
    export enum type {
        EMAIL = 'email',
        PHONE = 'phone',
        WEBSITE = 'website',
        WHATSAPP = 'whatsapp',
        SOCIAL_MEDIA = 'social-media',
    }
}

